module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Adventurees","short_name":"Adventurees","start_url":"/","theme_color":"#73cd30","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/adv-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c1b5fd4ca34dd6598aa449648eec47f3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/mnt/c/www/adventurees/sites/adventurees-group-corporate/src/messages","languages":["en","es","pt"],"defaultLanguage":"es","redirect":false,"redirectComponent":"/mnt/c/www/adventurees/sites/adventurees-group-corporate/src/components/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P8H2VV6","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
