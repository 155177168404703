// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-businessup-dev-index-js": () => import("./../../../src/pages/businessup-dev/index.js" /* webpackChunkName: "component---src-pages-businessup-dev-index-js" */),
  "component---src-pages-businessup-faqs-js": () => import("./../../../src/pages/businessup/faqs.js" /* webpackChunkName: "component---src-pages-businessup-faqs-js" */),
  "component---src-pages-businessup-index-js": () => import("./../../../src/pages/businessup/index.js" /* webpackChunkName: "component---src-pages-businessup-index-js" */),
  "component---src-pages-businessup-pricing-js": () => import("./../../../src/pages/businessup/pricing.js" /* webpackChunkName: "component---src-pages-businessup-pricing-js" */),
  "component---src-pages-businessup-product-tour-js": () => import("./../../../src/pages/businessup/product-tour.js" /* webpackChunkName: "component---src-pages-businessup-product-tour-js" */),
  "component---src-pages-businessup-testimonials-js": () => import("./../../../src/pages/businessup/testimonials.js" /* webpackChunkName: "component---src-pages-businessup-testimonials-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("./../../../src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-landing-businessup-js": () => import("./../../../src/pages/landing/businessup.js" /* webpackChunkName: "component---src-pages-landing-businessup-js" */),
  "component---src-pages-legal-advice-js": () => import("./../../../src/pages/legal-advice.js" /* webpackChunkName: "component---src-pages-legal-advice-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transparency-js": () => import("./../../../src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */)
}

