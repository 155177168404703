import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, location, image }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.baseUrl === '' && typeof window !== 'undefined') {
    defaults.baseUrl = window.location.origin;
  }
  const url = typeof window !== 'undefined' ? window.location.href : '';

  if (defaults.baseUrl === '') {
    console.error('Please set a baseUrl in your site metadata!');
    return null;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url}/>

      <meta name="description" content={description} />
      {image && <meta name="image" content={"https://www.adventurees-alliance.com"+image} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={"https://www.adventurees-alliance.com"+image} />}
      <meta property="og:url" content={url}/>
      <meta property="og:site_name" content={title}/>

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Adventurees" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={"https://www.adventurees-alliance.com"+image} />}
    </Helmet>
  );
};

export default SEO;